exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-case-study-jsx": () => import("./../../../src/pages/case-study.jsx" /* webpackChunkName: "component---src-pages-case-study-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lead-management-jsx": () => import("./../../../src/pages/lead-management.jsx" /* webpackChunkName: "component---src-pages-lead-management-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-product-overview-jsx": () => import("./../../../src/pages/product-overview.jsx" /* webpackChunkName: "component---src-pages-product-overview-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-use-cases-jsx": () => import("./../../../src/pages/use-cases.jsx" /* webpackChunkName: "component---src-pages-use-cases-jsx" */),
  "component---src-pages-why-vymo-jsx": () => import("./../../../src/pages/why-vymo.jsx" /* webpackChunkName: "component---src-pages-why-vymo-jsx" */)
}

